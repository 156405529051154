// Auto generated by npm run util:updateIcons at Tue Mar 11 2025 10:55:47 GMT+0100 (Central European Standard Time)

import Webhooks from './Webhooks.svg';
import Visibility from './Visibility.svg';
import Users from './Users.svg';
import Uptime from './Uptime.svg';
import Tag from './Tag.svg';
import Support from './Support.svg';
import Success from './Success.svg';
import Stop from './Stop.svg';
import Speed from './Speed.svg';
import SelfService from './SelfService.svg';
import Rules from './Rules.svg';
import Proxy from './Proxy.svg';
import NativeTooling from './NativeTooling.svg';
import Lock from './Lock.svg';
import Formats from './Formats.svg';
import Fixes from './Fixes.svg';
import Distribution from './Distribution.svg';
import Dependencies from './Dependencies.svg';
import Costs from './Costs.svg';
import Cloud from './Cloud.svg';
import Check from './Check.svg';
import CLI from './CLI.svg';
import Bindings from './Bindings.svg';
import Availability from './Availability.svg';
import API from './API.svg';

const iconMap = {
  webhooks: Webhooks,
  visibility: Visibility,
  users: Users,
  uptime: Uptime,
  tag: Tag,
  support: Support,
  success: Success,
  stop: Stop,
  speed: Speed,
  selfservice: SelfService,
  rules: Rules,
  proxy: Proxy,
  nativetooling: NativeTooling,
  lock: Lock,
  formats: Formats,
  fixes: Fixes,
  distribution: Distribution,
  dependencies: Dependencies,
  costs: Costs,
  cloud: Cloud,
  check: Check,
  cli: CLI,
  bindings: Bindings,
  availability: Availability,
  api: API
};

export default iconMap;
