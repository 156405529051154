import React from 'react';
import PropTypes from 'prop-types';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import * as styles from './Tooltip.module.css';

import InfoIcon from '@icons/InfoFilled.svg';

const Tooltip = ({ text, openDelay = 50 }) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={openDelay}>
        <RadixTooltip.Trigger asChild>
          <button className={styles.trigger}>
            <InfoIcon className={styles.triggerIcon} />
          </button>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            align="start"
            className={styles.tooltip}
            sideOffset={5}
          >
            {text}
            <RadixTooltip.Arrow className={styles.arrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  openDelay: PropTypes.number,
};

export default Tooltip;
