import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import iconMap from '@icons/index';
import brandIconMap from '@icons/brand/index';

import * as styles from './Icon.module.css';

/**
 * A small component that helps render icons across the app.
 * When rendering a brand icon, it automatically renders in a larger size.
 */
const Icon = ({ icon, className }) => {
  const brandIcon = brandIconMap[icon];
  const IconComponent = brandIcon ?? iconMap[icon];

  return (
    <IconComponent
      className={cn(styles.root, brandIcon && styles.brand, className)}
    />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(iconMap).concat(Object.keys(brandIconMap)))
    .isRequired,
};

export default Icon;
