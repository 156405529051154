// Auto generated by npm run util:updateIcons at Tue Mar 11 2025 10:55:47 GMT+0100 (Central European Standard Time)

import ViewMarkdown from './ViewMarkdown.svg';
import ViewHTML from './ViewHTML.svg';
import User from './User.svg';
import Upload from './Upload.svg';
import Trash from './Trash.svg';
import Tick from './Tick.svg';
import Sun from './Sun.svg';
import SubsetRestrictions from './SubsetRestrictions.svg';
import Stats from './Stats.svg';
import Star from './Star.svg';
import SpeechBubble from './SpeechBubble.svg';
import Share from './Share.svg';
import Setup from './Setup.svg';
import Service from './Service.svg';
import SecurityScan from './SecurityScan.svg';
import Secure from './Secure.svg';
import SearchRestrictions from './SearchRestrictions.svg';
import Search from './Search.svg';
import Resynchronize from './Resynchronize.svg';
import Redirect from './Redirect.svg';
import QuestionMark from './QuestionMark.svg';
import Quarantine from './Quarantine.svg';
import PushPull from './PushPull.svg';
import Premium from './Premium.svg';
import Plus from './Plus.svg';
import Play from './Play.svg';
import Pending from './Pending.svg';
import Pencil from './Pencil.svg';
import PackageInsightsFilled from './PackageInsightsFilled.svg';
import PackageInsights from './PackageInsights.svg';
import Package from './Package.svg';
import OpenSource from './OpenSource.svg';
import NotAcceptedEula from './NotAcceptedEula.svg';
import Moon from './Moon.svg';
import Minus from './Minus.svg';
import Logs from './Logs.svg';
import LockOpen from './LockOpen.svg';
import LockClosed from './LockClosed.svg';
import License from './License.svg';
import Key from './Key.svg';
import InfoFilled from './InfoFilled.svg';
import Info from './Info.svg';
import Flash from './Flash.svg';
import Flagged from './Flagged.svg';
import Filter from './Filter.svg';
import Download from './Download.svg';
import Distribute from './Distribute.svg';
import Diamond from './Diamond.svg';
import Desktop from './Desktop.svg';
import Copy from './Copy.svg';
import Compliance from './Compliance.svg';
import Close from './Close.svg';
import Clean from './Clean.svg';
import ChevronUp from './ChevronUp.svg';
import ChevronRight from './ChevronRight.svg';
import ChevronLeft from './ChevronLeft.svg';
import ChevronDown from './ChevronDown.svg';
import Burger from './Burger.svg';
import Binary from './Binary.svg';
import ArrowUp from './ArrowUp.svg';
import ArrowRight from './ArrowRight.svg';
import ArrowLeft from './ArrowLeft.svg';
import ArrowDown from './ArrowDown.svg';
import AcceptedEula from './AcceptedEula.svg';

const iconMap = {
  viewmarkdown: ViewMarkdown,
  viewhtml: ViewHTML,
  user: User,
  upload: Upload,
  trash: Trash,
  tick: Tick,
  sun: Sun,
  subsetrestrictions: SubsetRestrictions,
  stats: Stats,
  star: Star,
  speechbubble: SpeechBubble,
  share: Share,
  setup: Setup,
  service: Service,
  securityscan: SecurityScan,
  secure: Secure,
  searchrestrictions: SearchRestrictions,
  search: Search,
  resynchronize: Resynchronize,
  redirect: Redirect,
  questionmark: QuestionMark,
  quarantine: Quarantine,
  pushpull: PushPull,
  premium: Premium,
  plus: Plus,
  play: Play,
  pending: Pending,
  pencil: Pencil,
  packageinsightsfilled: PackageInsightsFilled,
  packageinsights: PackageInsights,
  package: Package,
  opensource: OpenSource,
  notacceptedeula: NotAcceptedEula,
  moon: Moon,
  minus: Minus,
  logs: Logs,
  lockopen: LockOpen,
  lockclosed: LockClosed,
  license: License,
  key: Key,
  infofilled: InfoFilled,
  info: Info,
  flash: Flash,
  flagged: Flagged,
  filter: Filter,
  download: Download,
  distribute: Distribute,
  diamond: Diamond,
  desktop: Desktop,
  copy: Copy,
  compliance: Compliance,
  close: Close,
  clean: Clean,
  chevronup: ChevronUp,
  chevronright: ChevronRight,
  chevronleft: ChevronLeft,
  chevrondown: ChevronDown,
  burger: Burger,
  binary: Binary,
  arrowup: ArrowUp,
  arrowright: ArrowRight,
  arrowleft: ArrowLeft,
  arrowdown: ArrowDown,
  acceptedeula: AcceptedEula
};

export default iconMap;
